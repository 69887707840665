//@flow
import React from 'react'
import { BlogLayout } from '../templates/BlogLayout'
import PostPreview from '../components/post-preview';
import usePosts from '../hooks/use-posts';

export default () => {
  const posts = usePosts()
  return <BlogLayout>
    <h2>
      What I will blog about
    </h2>
    <p>
      Advice, recipes or strategies that could make your life a tiny bit more productive.
    </p>
    <h2>
      Target audience
    </h2>
    <p>
      Anyone using computers a lot
    </p>
    <h2>
      Blog posts
    </h2>
    {
      posts.map(post => (
        <PostPreview key={post.slug} post={post}/>
      ))
    }
  </BlogLayout>
}
