//@flow
import R from 'ramda'
import moment from 'moment'
import { graphql, useStaticQuery }  from 'gatsby'

const usePosts = () => {
  const data = useStaticQuery(graphql`
    query {
        allGhostPost {
            nodes {
                title
                excerpt
                published_at
                slug
                primary_author {
                    name
                }
            }
        }
        allMdx(
            sort: { order: DESC, fields: [frontmatter___date] },
        ) {
            nodes {
                frontmatter {
                    title
                    slug
                    date
                }
                excerpt
            }
        }
    }
  `)

  const { allGhostPost: allGhostPosts, allMdx: allMdxPosts } = data

  const ghostToCommon = post => ({
    title: post.title,
    author: post.primary_author.name,
    slug: post.slug,
    published_at: post.published_at,
    excerpt: post.excerpt
  })

  const mdxToCommon = post => ({
    title: post.frontmatter.title,
    author: post.frontmatter.author,
    slug: post.frontmatter.slug,
    published_at: moment(post.frontmatter.date, 'YYYY/MM/DD').toISOString(),
    excerpt: post.excerpt
  });


  const allPosts = [
    ...allMdxPosts.nodes.map(mdxToCommon),
    ...allGhostPosts.nodes.map(ghostToCommon)
  ];

  return R.sortBy(p => -1*(new Date(p.published_at)), allPosts)
}

export default usePosts